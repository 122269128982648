// import { Controller } from "@hotwired/stimulus"
import { Controller } from "stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="visit"
export default class extends Controller {
  static targets = ["appointment_date"]

  connect() {
    // console.log("visit")
  }
  change(event) {
    // console.log(event.target)
    // console.log(event.target.dataset.value)
    // console.log('this')
    this.appointment_dateTarget.value = (new Date(event.target.dataset.value)).toISOString().substring(0, 16)

    get(`/update_time?time=${event.target.dataset.value}`, {
      responseKind: "turbo-stream"
    })
  }
}
