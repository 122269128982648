// import { Controller } from "@hotwired/stimulus"
import { Controller } from "stimulus"
// import "https://storage.googleapis.com/workbox-cdn/releases/6.4.1/workbox-sw.js"
// importScripts(
//   "https://storage.googleapis.com/workbox-cdn/releases/6.4.1/workbox-sw.js"
// );
// if( 'function' === typeof importScripts) {
//   importScripts("https://storage.googleapis.com/workbox-cdn/releases/6.4.1/workbox-sw.js");
//   addEventListener('message', onMessage);

//   function onMessage(e) { 
//     // do some work here 
//   }    
// }

// Connects to data-controller="push"
export default class extends Controller {
  // const {CacheFirst, NetworkFirst} = workbox.strategies;
  // const {registerRoute} = workbox.routing;

  connect() {
    // const {CacheFirst, NetworkFirst} = workbox.strategies;
    // importScripts("https://storage.googleapis.com/workbox-cdn/releases/6.4.1/workbox-sw.js");
    // const {registerRoute} = workbox.routing;
    // // If we have critical pages that won't be changing very often, it's a good idea to use cache first with them
    // registerRoute(
    //   ({url}) => url.pathname.startsWith('/'),
    //     new CacheFirst({
    //     cacheName: 'documents',
    //   })
    // )
    // // For every other page we use network first to ensure the most up-to-date resources
    // registerRoute(
    //   ({request, url}) => request.destination === "document" ||
    //     request.destination === "",
    //     new NetworkFirst({
    //       cacheName: 'documents'
    //     })
    //   )
    // // For assets (scripts and images), we use cache first
    // registerRoute(
    //   ({request}) => request.destination === "script" ||
    //   request.destination === "style",
    //   new CacheFirst({
    //   cacheName: 'assets-styles-and-scripts',
    //   })
    // )
    // registerRoute(
    //   ({request}) => request.destination === "image",
    //   new CacheFirst({
    //   cacheName: 'assets-images',
    //   })
    // )
    // const {warmStrategyCache} = workbox.recipes;
    // const {setCatchHandler} = workbox.routing;
    // const strategy = new CacheFirst();
    // const urls = ['/offline.html'];
    // // Warm the runtime cache with a list of asset URLs
    // warmStrategyCache({urls, strategy});
    // // Trigger a 'catch' handler when any of the other routes fail to generate a response
    // setCatchHandler(async ({event}) => {
    //   switch (event.request.destination) {
    //     case 'document':
    //       return strategy.handle({event, request: urls[0]});
    //     default:
    //     return Response.error();
    //   }
    // });
    // Check if the browser supports notifications
    if ("Notification" in window) {
      // Request permission from the user to send notifications
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          // If permission is granted, register the service worker
          this.registerServiceWorker();
        } else if (permission === "denied") {
          console.warn("User rejected to allow notifications.");
        } else {
          console.warn("User still didn't give an answer about notifications.");
        }
      });
    } else {
      console.warn("Push notifications not supported.");
    }
  }

  registerServiceWorker() {
    // Check if the browser supports service workers
    if ("serviceWorker" in navigator) {
      // Register the service worker script (service_worker.js)
      navigator.serviceWorker
        .register("/service-worker.js", { scope: "/" })
        .then((registration) => {
          if ("SyncManager" in window) {
            registration.sync.register("sync-forms");
          } else {
            window.alert("This browser does not support background sync.")
          }
        }).then(() => console.log("[Companion]", "Service worker registered!"))
        .then((serviceWorkerRegistration) => {
          // Check if a subscription to push notifications already exists
          serviceWorkerRegistration.pushManager
            .getSubscription()
            .then((existingSubscription) => {
              if (!existingSubscription) {
                // If no subscription exists, subscribe to push notifications
                serviceWorkerRegistration.pushManager
                  .subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: this.element.getAttribute(
                      "data-application-server-key"
                    ),
                  })
                  .then((subscription) => {
                    // Save the subscription on the server
                    this.saveSubscription(subscription);
                  });
              }
            });
        })
        .catch((error) => {
          console.error("Error during registration Service Worker:", error);
        });
    }
  }

  saveSubscription(subscription) {
    // Extract necessary subscription data
    const endpoint = subscription.endpoint;
    const p256dh = btoa(
      String.fromCharCode.apply(
        null,
        new Uint8Array(subscription.getKey("p256dh"))
      )
    );
    const auth = btoa(
      String.fromCharCode.apply(
        null,
        new Uint8Array(subscription.getKey("auth"))
      )
    );

    // Send the subscription data to the server
    fetch("/cabinet/push_notifications/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify({ endpoint, p256dh, auth }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Subscription successfully saved on the server.");
        } else {
          console.error("Error saving subscription on the server.");
        }
      })
      .catch((error) => {
        console.error("Error sending subscription to the server:", error);
      });
  }
}
