// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import consumer from './channels/consumer'
import CableReady from "cable_ready"
import mrujs from "mrujs";
import { CableCar } from "mrujs/plugins"
// import "./custom/companion"

mrujs.start({
  plugins: [
    new CableCar(CableReady, { mimeType: "application/vnd.cable-ready.json" })
    // new CableCar(CableReady)
  ]
})

CableReady.initialize({ consumer })
import "trix"
import "@rails/actiontext"
