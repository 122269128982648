// import { Controller } from "@hotwired/stimulus"
import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["appointment_master"]
  static service_id = 0
  connect() {
    // this.appointment_masterTarget.disabled = true
    // get(`/get_masters?service_id=${window.location.href.split('=').pop()}`, {
    //   responseKind: "turbo-stream"
    // })
    get(`/get_services?service_id=${window.location.href.split('=').pop()}`, {
      responseKind: "turbo-stream"
    })
  }
  change(event) {
    this.service_id = event.target.selectedOptions[0].value
    // console.log(this.service_id)
    
    get(`/get_services?service_id=${this.service_id}`, {
      responseKind: "turbo-stream"
    })
    // this.appointment_masterTarget.disabled = false
    // get(`/get_masters?service_id=${this.service_id}`, {
    //   responseKind: "turbo-stream"
    // })
    // get(`/get_business_days?service_id=${this.service_id}`, {
    //   responseKind: "turbo-stream"
    // })
  }
  change_service(event) {
    var labels = document.querySelectorAll(`label[for^="service_service_id_"]`)
    labels.forEach(label => {
      label.classList.remove('checked');
    });
    
    var label = document.querySelector(`label[for=${event.target.id}]`)
    label.classList.add('checked')
    
    this.service_id = event.target.defaultValue
    get(`/get_masters?service_id=${this.service_id}`, {
      responseKind: "turbo-stream"
    })
    get(`/get_business_days?service_id=${this.service_id}`, {
      responseKind: "turbo-stream"
    })
  }
  change_master(event) {
    var labels = document.querySelectorAll(`label[for^="master_master_id_"]`)
    labels.forEach(label => {
      label.classList.remove('checked');
    });

    var label = document.querySelector(`label[for=${event.target.id}]`)
    label.classList.add('checked')

    let master_id = event.target.defaultValue
    // console.log(service_id)
    // console.log(master_id)
    // console.log(event)
    let serv = ''
    if (this.service_id === undefined) {
      serv = window.location.href.split('=').pop()
    } else {
      serv = this.service_id
    }
    get(`/get_business_days?master_id=${master_id}&service_id=${serv}`, {
      responseKind: "turbo-stream"
    })
  }
}
